<template>
  <v-list-item
    v-if="userLearnPortalLink"
    target="_blank"
    :href="userLearnPortalLink"
  >
    <v-list-item-icon>
      <icon-base fontIcon icon="school" />
    </v-list-item-icon>
    <v-list-item-title class="pr-2"> Learn Portal </v-list-item-title>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'LearnPortalLink',
  computed: {
    ...mapGetters('users', ['currentUser']),
    userLearnPortalLink() {
      const { training_pid } = this.currentUser;
      if (!training_pid) return '';
      return `https://learn.conveyour.com/ui/portal/${training_pid}`;
    },
  },
};
</script>
