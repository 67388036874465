import Command from './command';
import EventBus from '@/factories/eventBus';

class OpenChatBot extends Command {
  id = 'openChatBot';
  icon = 'custom/ai';
  title = 'Ask CY';
  permission = 'cybot';

  run() {
    EventBus.$emit('toggleChatBot');
    return true;
  }
}

export default OpenChatBot;
