<template>
  <v-list-item @click="toggleChatBot">
    <v-list-item-icon>
      <icon-base icon="custom/ai" />
    </v-list-item-icon>
    <v-list-item-title class="pr-2"> Ask CY </v-list-item-title>
  </v-list-item>
</template>

<script>
import EventBus from '@/factories/eventBus';

export default {
  name: 'CustomerSupportChatBotLink',
  methods: {
    toggleChatBot() {
      EventBus.$emit('toggleChatBot');
    },
  },
};
</script>
